.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
.marker:hover {
  z-index: 1;
}
.pin {
  width: 28px;
  height: 28px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  top: 50%;
  margin: -20px 0 0 -20px;
  border: 1px rgb(255, 255, 255) solid;
}
.pin:after {
  content: "";
  width: 12px;
  height: 12px;
  margin: 8px 0 0 8px;
  right: 8px;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-200px) rotate(-45deg);
  }

  40% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  60% {
    transform: translateY(-10px) rotate(-45deg);
  }

  80% {
    transform: translateY(5px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
.pulse {
  box-shadow: 0 0 10px 2px
    linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%
    );
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: absolute;
  z-index: 0;
  border-radius: 50%;
  margin: 0px 0px 0px 0px;
}
.pulse:after {
  z-index: 1000;
  content: "";
  animation: pulsate 3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 10px 2px white;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(1.1, 1.1) rotate(-45deg);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.2, 1.2) rotate(-45deg);
    opacity: 1;
  }

  100% {
    transform: scale(1.1, 1.1) rotate(-45deg);
    opacity: 0.5;
  }
}
.infowindowEdm {
  display: flex;
  position: absolute;
  padding: 15px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  border: 1px black solid;
  border-radius: 30px;
  transform: translate(-50%, 50%);
}
.infowindow {
  display: flex;
  position: absolute;
  padding: 15px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  border: 1px black solid;
  border-radius: 30px;
  transform: translate(-50%, 50%);
}
.mapicons {
  display: flex;
  position: absolute;
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
.markercluster {
  display: flex;
  position: absolute;
  border-radius: 45px;
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 118, 172);
  border: 1px white solid;
  color: white;
  text-align: center;
}

.player {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff5722;

  animation: bouncePlayer 3s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}

@keyframes bouncePlayer {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 200px, 0);
  }
}
