.tilesbackdropmap {
  display: block;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
}
.tilesbackdropclosedmap {
  display: block;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
}
.tiles_slide-drawermap {
  display: flex;
  position: fixed;
  background: white;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px;
  height: auto;
  width: 90%;
  top: 56px;
  border-radius: 45px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, 0%);
  transition: transform 0.3s ease-out;
  left: 50%;
  margin-bottom: 200px;
}
.tiles_slide-drawer {
  position: fixed;
  display: none;
  transform: translateX(100%);
  margin-bottom: 200px;
}
.tilesset {
  flex-wrap: wrap;
  border: 1px solid white;
  height: min-content;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 30px;
}
.tilesselected {
  display: flex;
  position: relative;
  padding: 5px;
  height: min-content;

  width: 95%;
  border-radius: 30px;
}
.tilesnotselected {
  display: flex;
  position: relative;
  padding: 5px;
  height: min-content;

  width: 95%;
  border-radius: 30px;
}

.createphoto {
  display: flex;
  position: relative;
  top: 0%;
  width: 96%;
  border-radius: 30px;
  max-height: auto;
  padding: 5px;
  left: 50%;
  transform: translate(-50%);
}
.relative {
  display: flex;
  position: relative;
}
.typecircular {
  display: flex;
  position: relative;
  border-radius: 45px;
  height: 100px;
  width: 100px;
  align-self: right;
}
