.Etypebackdropmap {
  display: block;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
}
.Etypebackdropclosedmap {
  display: block;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
}
.eventtypesmap {
  display: flex;
  position: absolute;
  background: white;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px;
  height: min-content;

  width: 90%;
  top: 56px;
  border-radius: 45px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-out;
  left: 50%;
  align-items: center;
  align-content: flex-start;
  transform: translate(-50%, 0%);
  margin-bottom: 56px;
}
.eventtypes.closedmap {
  position: absolute;
  display: none;
  transform: translateX(100%);
  margin-bottom: 200px;
}
.eventtypessetmap {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  height: min-content;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
}
.eventtypesselected {
  height: min-content;
  max-height: 100;
  display: flex;
  position: absolute;
  border: 2px solid white;
}
.eventtypesnotselected {
  height: min-content;
  max-height: 100;
  display: flex;
  position: absolute;
  border: 2px solid grey;
}
.createphoto {
  display: flex;
  position: relative;
  top: 0%;
  width: 96%;
  border-radius: 30px;
  height: auto;
  padding: 5px;
  left: 50%;
  transform: translate(-50%);
}
.relative {
  display: flex;
  position: relative;
} /*
.socialtypes{
  display:flex;
  position:relative;
  width:40%;
  font-size: 25px;
  background-color: rgb(18, 28, 54);
  color:white;
  padding:10px;
  margin:10px;
  border-radius:10px;
}*/
