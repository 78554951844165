.login {
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: grey;
  font-size: 26px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}
.form1 {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 99%;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.868);
  border-radius: 45px;
  border: #333 1px solid;
  padding: 30px 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9998;
}
.input-field {
  height: 36px;
  width: 60vw;
  max-width: 260px;
  margin: 2px 0px;
  color: black;
  font-size: 20px;
}
.input-fielddark {
  height: 36px;
  width: 60vw;
  max-width: 260px;
  margin: 2px 0px;
  color: black;
  background-color: #777;
  font-size: 20px;
}
.numberdropdown {
  color: black;
}
.loginsignup {
  display: flex;
  font-size: 44px;
  padding-top: 20px;
}
.loginsentence {
  display: flex;
  position: relative;
  font-size: 26px;
  padding-top: 10px;
}
.loginwarning {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 12px;
  color: rgb(199, 199, 199);
  padding: 0px 5%;
  padding-top: 10px;
  margin-bottom: 5px;
}
.loginwarning div {
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.loginbtn {
  display: grid;
  border: #333 1px solid;
  justify-self: center;
  background-color: white;
  width: 140px;
  height: 56px;
  font-size: 26px;
  border-radius: 45px;
}
.previewbtn {
  display: flex;
  position: relative;
  margin-top: 5vh;
  border: #333 1px solid;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 140px;
  height: 56px;
  font-size: 26px;
  border-radius: 45px;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-height: 600px) {
  .previewbtn {
    display: none;
  }
  .form1 {
    margin-bottom: 10px;
  }
  .showrecaptcha {
    margin-bottom: 10px;
  }
  .showphonecodeform {
    margin-bottom: 10px;
  }
}
video {
  position: fixed;
  height: 100%;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: -100;
  background-size: cover;
}
.alternative {
  position: fixed;
  z-index: -101;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-size: cover;
} /*
.bigdot{
  display:flex;
  position:fixed;
  font-size:50px;
  color:white;
  left:30px;
  bottom:20px;

}*/
.showphonecodeform {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 300px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  border: #333 1px solid;
  padding: 30px;
  margin-bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.hiderecaptcha {
  display: none;
}
.showrecaptcha {
  display: grid;
  position: relative;
  margin-top: 5vh;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  border: #333 1px solid;
  margin-bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.showphonecodeformbtn {
  background-color: rgb(0, 102, 255);
  border-radius: 30px;
  color: white;
  font-size: 16px;
}
.showphonecodeforminput {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 30px;
  font-size: 16px;
}
.phonecodeinput {
  cursor: pointer;
  width: calc(100% - 20px);
  color: #111;
  font-size: 16px;
}
.phonecodeinput::placeholder {
  color: #777;
  font-size: 16px;
}
.phonecodeinput input::placeholder {
  color: blue;
}
.openSignupVid {
  display: flex;
  position: fixed;
  top: 30px;
  right: 10px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  border: #333 1px solid;
}
.plusrightexit {
  display: flex;
  position: fixed;
  right: 10px;
  bottom: 30px;
  color: white;
  font-size: 30px;
  background-color: #333;
  padding: 15px;
  padding-top: 5px;
  border-radius: 30px;
  z-index: 9999;
}
@media (min-height: 600px) {
  .plusrightexit {
    display: none;
  }
}
.spaceforphone {
  margin-top: 0px;
}
.signupConfirmVideo {
  display: flex;
}
.signupConfirmHidden {
  display: none;
  transform: translateX(100%);
}
.raisesignup {
  display: flex;
  position: fixed;
  z-index: 10000;
}
.hidesignup {
  display: flex;
  position: fixed;
  z-index: 10000;
}
.showsignup {
  display: flex;
  position: fixed;
  z-index: 100;
  transform: translateY(100%);
}
.signupvideo {
  display: flex;
  position: fixed;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
.playpausebutton {
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.playpausebuttonhidden {
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
}
.closesignupvideobutton {
  display: flex;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
}
.closesignupvideobuttonhidden {
  display: flex;
  position: fixed;
  bottom: -830px;
  left: 50%;
  transform: translate(-50%, 100%);
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
}
.videoscroller {
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: 30px;
  left: 30px;
  height: 250px;
  width: 30px;
  border-radius: 35px;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.videoscrollerhidden {
  display: flex;
  position: fixed;
  flex-direction: column;
  left: 30px;
  height: 250px;
  width: 30px;
  border-radius: 35px;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
  transform: translateY(200%);
}
.signUpPushToplay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(51, 51, 51, 0.473);
}
.signUpPushToplayhidden {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(51, 51, 51, 0.473);
  transform: translateY(100%);
}
.alternativephoto {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}
.alternativephotohidden {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -101;
  transform: translateY(100%);
  background-size: cover;
}
.signbackground-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 10;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  color: grey;
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}
.PhoneInput input::placeholder {
  color: grey;
}

.PhoneInputInput {
  color: grey;
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
