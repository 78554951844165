.mcslide-drawerfixedmap {
  display: flex;
  position: fixed;
  height: 100%;
  box-sizing: border-box;
  top: 56px;
  z-index: 6000;
  width: 100%;
  height: 100%;
  /*text-align: center;
  align-items: center;*/
  transition: transform 0.3s ease-out;
}
.featuredcitysetmap {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  right: 0px;
  justify-content: flex-end;
  z-index: -1;
  top: 56px;
  left: 0px;
  border: 1px solid white;
}
.featuredcitymap {
  display: flex;
  position: relative;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  width: 45vw;
  height: auto;
  max-width: 200px;
  right: 0px;
}
.featuredcitymap1 {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  max-height: 100px;
  min-height: 26px;
  max-width: 200px;
}
.featuredcitymap1 div {
  font-size: 14px;
}
@media (min-width: 600px) {
  .featuredcitymap1 div {
    font-size: 18px;
  }
}
.onecity {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin: 1px;
  padding-bottom: 5px;
  padding: 10px;
  z-index: 1;
  height: 260px;
  width: 185px;
  padding-bottom: 20px;
  background-color: #333;
  color: rgba(255, 255, 255, 0.644);
  font-size: 20px;
  border: 1px white solid;
}
.citybundlemap1 {
  display: flex;
  position: relative;
  top: 0px;
  justify-content: center;
  align-items: center;
  height: 185px;
  width: 185px;
}
.citybundlemap {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 185px;
  width: 185px;
}
.loadingcitiesresults {
  display: flex;
  z-index: 9000;
  background-color: white;
  height: 56px;
  width: 120px;
  border-radius: 30px;
  border: 1px solid #333;
  justify-content: center;
  align-items: center;
}
.backSWback {
  display: flex;
  position: absolute;
  right: 60vw;
  width: 56px;
  top: 0px;
  z-index: 1000;
}
.searchSWsearch {
  display: flex;
  position: absolute;
  right: 60vw;
  width: 56px;
  top: 0px;
  z-index: 1000;
}
.yrnotagline {
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 3%;
  width: 227px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.928);
  border: 1px solid #333;
  border-radius: 30px;
  text-align: left;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-shadow: inset 0 0 50px rgba(255, 255, 255, 0);
  z-index: 9999;
}
@media (max-height: 600px) {
  .yrnotagline {
    bottom: 5px;
    left: 5px;
  }
}
.pwrGoogle {
  display: flex;
  position: fixed;
  right: 82px;
  top: 29px;
  z-index: 9999;
  background-color: white;
  border-radius: 30px;
  padding: 2px 6px;
}
.topoffenter {
  display: flex;
  position: fixed;
  padding: 10px 0px;
  top: 56px;
  left: 0px;
  width: 300px;
  background-color: #333;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid white;
  color: white;
}

.radiusExpectedToolbar {
  display: flex;
  position: absolute;
  right: 0px;
  padding-left: 15px;
  padding-right: 15px;
  height: 250px;
  border-radius: 35px;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  background-color: #333;
  border: 1px solid white;
  align-items: center;
  font-size: 19px;
  z-index: 000;
}
.radiusnumber {
  display: flex;
  padding-right: 15px;
  text-align: center;
}
.buyerthestuff span span {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.buyerthestuff span span input {
  margin-right: 14px;
  margin-bottom: 19px;
}
.inputbuyertitle {
  font-size: 18px;
  padding: 5px;
  margin: 5px;
  border: 0.3px solid #333;
}
.inputbuyertitle::placeholder {
  color: #777;
  font-size: 18px;
  padding: 0px 5px;
}
.buyerthestuff p {
  color: #777;
  font-size: 14px;
  cursor: pointer;
}

.buyerthestuff div p {
  color: black;
  font-size: 17px;
  margin-top: 10px;
}
.buyerthestuff div p span {
  color: rgb(53, 98, 202);
  font-size: 17px;
}
.buyerthestuff div p span span {
  color: #777;
  font-size: 17px;
  margin-top: -10px;
}
.openaddcomm {
  display: flex;
  position: relative;
  width: 350px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  height: 56px;
  color: black;
  background-color: white;
}

.optionbuy {
  display: flex;
  position: relative;
  flex-direction: column;
}
.optionbuy2 {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 40px;
}
.buyerthestuff span span div p {
  display: flex;
  position: relative;
  color: #777;
  font-size: 14px;
}
.pricetag {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #777;
  font-size: 14px;
  margin: 20px;
}

.pricetag div {
  display: grid;
  padding: 5px;
  border: 1px solid black;
  color: #777;
  font-size: 14px;
}
.forcitydivs div div {
  color: white;
  background-color: rgb(30, 20, 20);
  padding: 10px;
}
