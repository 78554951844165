.eventsettings-drawer {
  display: flex;
  position: none;
  height: 30%;
  background: white;
  box-sizing: border-box;
  top: 56px;
  width: 100%;
  height: calc(100% - 56px);
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-out;
  transform: translateX(100%);
}
.eventsettings-drawer.open {
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
}

.backnewcreateevent {
  display: flex;
  position: fixed;
  height: 56px;
  width: 56px;
  left: 0px;
  top: 0px;
  z-index: 9999;
}

.settings {
  position: fixed;
  padding-top: 10px;
  padding-right: 13px;
  height: 36px;
  width: 36px;
  right: 0px;
  top: 0px;
  z-index: 9999;
}
.cancelphoto {
  display: flex;
  position: fixed;
  color: white;
  top: 63px;
  right: 20px;
  font-size: 40px;
  z-index: 101;
}

.event-form-details {
  padding: 10px;
  font-size: 19px;
  display: flex;
  position: relative;
  top: 16px;
  height: 200px;
  width: calc(90% - 10px);
  bottom: 30px;
  color: #444;
  left: 50%;
  transform: translateX(-50%);
}

.eventphotobackgrounddiv1 {
  display: flex;
  position: fixed;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  border: none;
  background-color: black;
  overflow: hidden;
}
.eventphotobackgroundimg {
  display: flex;
  position: fixed;
  width: 100%;
  height: auto;
  border: none;
  background-color: black;
  z-index: -99;
}

.eventtypeselected {
  user-select: none;
  font-size: 15px;
  border: 2px solid;
  padding: 4px;
  margin: 2px;
  color: white;
}
.eventtypepotential {
  user-select: none;
  color: grey;
  font-size: 15px;
  border: 2px solid;
  padding: 4px;
  margin: 2px;
}

.todaytomorrowevento {
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 15px;
  color: white;
  top: 52px;
  padding: 30px 0px;
  justify-content: flex-start;
  font-size: 20px;
  z-index: 101;
  width: 100%;
  height: 35px;
}
.event-form {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0px;
  left: 50%;
  height: 150px;
  color: #444;
  font-size: 26px;
  flex-direction: row;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid white;
}

.titleofevento {
  display: flex;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 56px;
  bottom: 40%;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  font-size: 26px;
  z-index: 99;
}
.titleofeventp {
  display: flex;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 56px;
  bottom: 40%;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  font-size: 26px;
  z-index: 99;
}
.titleofeventp::placeholder {
  color: rgb(160, 160, 160);
}

.arrowright1o {
  position: fixed;
  bottom: 45%;
  padding-right: 15px;
  padding-top: 15px;
  height: 45px;
  width: 45px;
  right: 30px;
  z-index: 100;
}
.timero {
  position: fixed;
  padding-right: 15px;
  padding-top: 15px;
  height: 36px;
  width: 36px;
  z-index: 9999;
  bottom: 45%;
  left: 30px;
}
@media (min-height: 600px) {
  .arrowright1o {
    position: fixed;
    bottom: 41%;
    padding-right: 15px;
    padding-top: 15px;
    height: 45px;
    width: 45px;
    right: 30px;
    z-index: 100;
  }
  .timero {
    position: fixed;
    padding-right: 15px;
    padding-top: 15px;
    height: 36px;
    width: 36px;
    z-index: 9999;
    bottom: 41%;
    left: 30px;
  }
}

.pexelsmemo {
  display: flex;
  position: fixed;
  background-color: white;
  border: 1px #333 solid;
  border-radius: 30px;
  z-index: 1000;
  bottom: 0px;
  padding: 0px 30px;
}

.eventnewpicker_backdrop {
  display: flex;
  position: fixed;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
}

.relativeflexconfirmevent {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  left: 0px;
  top: 86px;
  width: 100%;
  justify-content: center;
  z-index: 9999;
  transform: translateY(-12%);
  padding-bottom: 100px;
}

.eventnewcitysearch {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #333;
  color: white;
  padding: 30px;
  margin: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  border-radius: 20px;
  height: min-content;
}
.eventnewcitysearchfilled {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #333;
  color: white;
  padding: 30px;
  margin: 5px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  font-size: 23px;
  border-radius: 20px;
  height: min-content;
}
.searchboxthing {
  display: flex;
  position: relative;
}
.eventholduppicker_backdrop {
  display: flex;
  position: fixed;
  justify-content: center;
  color: white;
  width: 100%;
  height: 45%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  z-index: 2999;
}

.addticketform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0px;
}
.addticketform div input::placeholder {
  color: #777;
}

.loadingAuthScreen1 {
  display: flex;
  transform: translateZ(1px);
}
.loadingAuthScreen1 div {
  display: flex;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  margin-bottom: 75px;
}

.inputdatepicker * {
  font-size: 10;
  color: white;
  width: max-content;
  position: fixed;
  background-color: transparent;
  z-index: 19;
}
