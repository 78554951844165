.profile_backdrop {
  display: flex;
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  overflow: auto;
  color: white;
}
.profile_slide-drawer {
  display: flex;
  position: fixed;
  background: white;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px;
  top: 76px;
  width: 90%;
  left: 5%;
  z-index: 5;
  border-radius: 45px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateY(800%);
  transition: transform 0.3s ease-out;
}
.profile_slide-drawer.open {
  display: flex;
  position: absolute;
  transform: translateY(0);
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px;
  top: 76px;
  width: 90%;
  left: 5%;
  z-index: 5;
  margin-bottom: 80px;
  border-radius: 45px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-out;
  overflow-x: hidden;
  overflow-y: hidden;
}
.profileinfo {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 5px 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  top: 0px;
}
.inputforname::placeholder {
  color: #999;
}
.logoutbtn {
  height: 56px;
}
aside.emoji-picker-react-no {
  display: none;
}

.loginbtn1 {
  display: flex;
  border: #333 1px solid;
  justify-self: center;
  background-color: white;
  padding: 5px 30px;
  font-size: 18px;
  border-radius: 45px;
  height: 56px;
  flex-direction: column;
}
