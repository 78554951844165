.city_headermenu {
  position: fixed;
  z-index: 2;
  max-height: 15vh;
  max-width: 30vw;
  top: 30vh;
  right: 5%;
  transform: translate(-5%, -50%);
  z-index: 2;
}
.border_headermenu {
  position: fixed;
  z-index: 2;
  max-height: 15vh;
  max-width: 30vw;
  top: 30vh;
  right: 5%;
  transform: translate(-5%, -50%);
  z-index: 3;
}
.stars_headermenu {
  position: fixed;
  z-index: 2;
  max-height: 15vh;
  max-width: 30vw;
  top: 30vh;
  right: 5%;
  transform: translate(-5%, -50%);
  z-index: 2;
}
.sky_headermenu {
  position: fixed;
  z-index: 2;
  max-height: 15vh;
  max-width: 30vw;
  top: 30vh;
  right: 5%;
  transform: translate(-5%, -50%);
  z-index: 1;
}

.Menu {
  display: flex;
  position: fixed;
  z-index: 9999;
  padding-left: 0%;
  height: 100%;
  width: 100%;
  text-decoration: none;
  background-color: #5d2a86d8;
  color: rgb(255, 255, 255);
  font-size: 26px;
}

.invites_menu {
  position: fixed;
  top: 20px;
  right: 12px;
  height: 30px;
  width: 36px;
  z-index: 3;
}
.chatmenu {
  position: fixed;
  z-index: 2;
  max-height: 15vh;
  max-width: 30vw;
  bottom: 20vh;
  left: 5%;
  transform: translate(-5%, -50%);
  z-index: 2;
}
.globemenu {
  position: fixed;
  bottom: 15%;
  max-height: 15vh;
  max-width: 30vw;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, 20%);
}
.eventsmenu {
  position: fixed;
  z-index: 2;
  bottom: 30vh;
  max-height: 15vh;
  max-width: 30vw;
  right: 7%;
  z-index: 2;
}
.logout_menu {
  position: fixed;
  top: 20px;
  left: 10px;
  height: 36px;
  width: 36px;
  z-index: 3;
}
.loginlogout {
  position: fixed;
  padding-top: 20px;
  padding-left: 50px;
  height: 56px;
  color: white;
  text-decoration: none;
}

.clockdialmenu {
  display: flex;
  position: fixed;
  max-height: 20vh;
  max-width: 35vw;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.clockbordermenu {
  display: flex;
  position: fixed;
  z-index: 100;
  max-height: 20vh;
  max-width: 35vw;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.todayonmenu {
  display: flex;
  position: fixed;
  left: 50%;
  top: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  font-size: 22px;
  transform: translate(-50%, -50%);
}
.menutoday {
  display: flex;
  position: relative;
  text-decoration: none;
  color: white;
  font-size: 22px;
}
.menutodaydate {
  display: flex;
  position: relative;
  text-decoration: none;
  color: white;
  font-size: 55px;
}
.menucontainer {
  max-width: 50vw;
}
.questionmark {
  display: flex;
  position: fixed;
  left: 20px;
  bottom: 20px;
  color: white;
  font-size: 50px;
}

/*#root {
  color: #fff;
  font-size: 32px;
  font-family: PT Sans, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 200;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
  background-color: #ae53f8;
  width: 100%;
  height: 100%;
  display: flex;
}

.box {
  top: 50%;
  left: 50%;
  height: 42px;
  width: 42px;
  z-index: 2;
  display: flex;
}*/
