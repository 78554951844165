.mcslide-drawer {
  display: flex;
  position: fixed;
  height: 100%;
  background: white;
  box-sizing: border-box;
  top: 0px;
  z-index: 6000;
  width: 100%;
  height: 100%;
  /*text-align: center;
  align-items: center;*/
  transition: transform 0.3s ease-out;
}
.mcslide-drawerfixed {
  display: flex;
  position: fixed;
  height: 100%;
  background: white;
  box-sizing: border-box;
  top: 0px;
  z-index: 6000;
  width: 100%;
  height: 100%;
  /*text-align: center;
  align-items: center;*/
  transition: transform 0.3s ease-out;
}
.Cities {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  justify-content: center;
  align-content: flex-start;
  z-index: 6000;
  width: 100%;
  top: 57px;
  background-color: white;
  color: rgba(255, 255, 255, 0.644);
  font-size: 26px;
  transition: ease-out 0.1s;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid #333;
}
.featuredcityset {
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  position: absolute;
  z-index: -1;
  margin-top: 56px;
}
.featuredcity {
  display: flex;
  position: relative;
  padding-top: 5px;
  padding-right: 5px;
  width: 100vw;
  height: auto;
  max-width: 600px;
}
.Cities > div {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin: 1px;
  padding-bottom: 5px;
  height: 260px;
  width: 185px;
  padding-bottom: 20px;
  background-color: #333;
  color: rgba(255, 255, 255, 0.644);
  font-size: 20px;
}
.invites_large {
  top: 13px;
  right: 12px;
  height: 30px;
  width: 36px;
  z-index: 3;
}
.citybundle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 185px;
  width: 185px;
  border: 1px solid #333;
}
.border {
  display: flex;
  position: relative;
  text-align: center;
  height: 265px;
  width: 265px;
  z-index: 3;
  transform: translate(-50%, -50%);
}
.stars {
  display: flex;
  position: absolute;
  height: 200px;
  width: 200px;
  transform: translate(-50%, -50%);
}
.stars1 {
  display: flex;
  position: absolute;
  height: 200px;
  width: 200px;
  transform: translate(-50%, 0%);
}
.sky {
  display: flex;
  position: absolute;
  margin-top: 10px;
  height: 180px;
  width: 180px;
  transform: translate(-50%, -50%);
}
.sky1 {
  display: flex;
  position: absolute;
  height: 100px;
  width: 100px;
  transform: translate(-50%, -50%);
}
.sky2 {
  display: flex;
  position: absolute;
  height: 55px;
  width: 55px;
  transform: translate(-50%, -50%);
}
.showSW {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  transform: translateX(0%);
  z-index: 2000;
  transition: transform 0.3s ease-out;
  overflow-x: hidden;
}
.hideSW {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  transform: translateX(100%);
  z-index: 2000;
  transition: transform 0.3s ease-out;
  overflow-x: hidden;
}
.dontdisplay {
  display: hidden;
  position: fixed;
}
.dodisplay {
  display: flex;
  position: fixed;
}
