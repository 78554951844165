.hideMap {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transform: translateY(200%);
  z-index: 2;
}

.squeezer {
  top: 56px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
}
.mapoutlinesquare {
  display: flex;
  position: fixed;
  height: 250px;
  width: 250px;
  z-index: 2;
  background-color: #333;
  border: white 1px solid;
}
.touchaction {
  touch-action: none;
}
.mapzoom1 {
  height: min-content;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in;
}
.mapzoom1 div div {
  font-size: 30px;
  color: white;
  background-color: rgba(51, 51, 51, 0.421);
  border-radius: 45px;
  margin-left: 2.5px;
  border: 1px solid white;
  margin: 10px 0px;
}
.relativesearchmap1 {
  display: grid;
  position: relative;
  flex-direction: row;
  z-index: 101;
}
.relativesearchmap2 {
  display: grid;
  position: relative;
  flex-direction: row;
  z-index: 100;
}
.closesearchmap {
  display: flex;
  position: fixed;
  left: 350px;
  top: 70px;
  color: white;
  font-size: 40px;
}
.zoomoutquick1 {
  display: flex;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.6);
  color: #888;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.zoomoutquick {
  display: flex;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.6);
  color: #888;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
} /*
@media screen and (max-device-width:.2){
  .zoomoutquick{
  }
}*/
.mapmarkercomingup {
  transform: translate(-50%, -50%);
}
.mapmarkercomingupscrollno {
  display: none;
}
.placesresultsholder {
  display: flex;
  position: relative;
  flex-direction: column;
  top: 5px;
  width: 240px;
  height: 80%;
  background-color: #333;
  border-radius: 10px;
}
.placesresults {
  display: flex;
  position: relative;
  top: 0px;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  border: 1px #333 solid;
  z-index: 100;
  border-radius: 10px;
  font-size: 16px;
  padding: 5px;
  margin: 2px;
}
.zoomerbackground {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.639);
  z-index: -4;
}

.square_dotmap {
  display: flex;
  position: fixed;
  top: 0;
  right: 0px;
  height: 56px;
  width: 56px;
  z-index: 4000;
}
.filter_tilesmap {
  display: flex;
  position: fixed;
  top: 0;
  right: 56px;
  height: 56px;
  width: 56px;
  z-index: 1009;
}

.square_dot_typesmap {
  display: flex;
  position: fixed;
  top: 0;
  right: 0px;
  height: 56px;
  width: 56px;
  z-index: 1009;
}
.citylabel {
  display: flex;
  position: fixed;
  color: white;
  z-index: 9000;
  left: 86px;
  top: 80px;
}
.closemapper {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0px;
  left: 8px;
  z-index: 9999;
  font-size: 40px;
  color: white;
}
.lefttoggle {
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 70px;
  height: 56px;
  width: 56px;
  background-color: white;
  color: #333;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.righttoggle {
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 70px;
  height: 56px;
  width: 56px;
  background-color: white;
  color: #333;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.markerzero {
  display: absolute;
  margin: 0px;
}

.mplaceRadiusOnMap {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mcontainradius {
  display: flex;
  position: absolute;
  max-width: 120px;
}
.calendaropenmap {
  display: flex;
  position: relative;
  height: min-content;
  width: 100vw;
  padding: 10px;
  flex-direction: column;
}
.calendarclosemap {
  display: none;
}
.react-calendar__month-view__days__day {
  justify-content: center;
  padding: 5px;
}
.react-calendar__month-view__days__day--weekend {
  color: grey;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  color: black;
  font-size: 10px;
}
.editcolorheader::placeholder {
  color: rgb(100, 150, 200);
}
.formforum {
  background-color: #844fff;
}
.formforum input::placeholder {
  display: flex;
  padding: 3px 0px;
  font-size: 15px;
  text-indent: 6px;
  max-height: 300px;
  color: grey;
}
.target {
  content: "";
  animation: pulsate 3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0);
    transform: scale(1.1, 1.1);
    opacity: 0.7;
  }

  50% {
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0);
    transform: scale(1.2, 1.2);
    opacity: 1;
  }

  100% {
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0);
    transform: scale(1.1, 1.1);
    opacity: 0.7;
  }
}
