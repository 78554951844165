.tilesbackdropmap1 {
  display: block;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  top: 0;
  right: 0;
}
.tilesbackdropclosedmap1 {
  display: none;
}
.tiles_slide-drawermap1 {
  display: flex;
  position: absolute;
  background: white;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px;
  height: auto;
  width: 90%;
  top: 56px;
  z-index: 9999;
  border-radius: 45px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, 0%);
  transition: transform 0.3s ease-out;
  left: 50%;
  margin-bottom: 200px;
}
.tiles_slide-drawer.closedmap1 {
  display: none;
}
.tilesset1 {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.tilesset1 div {
  display: flex;
  align-items: center;
}
.tilesselected1 {
  display: flex;
  position: relative;
  top: 0px;
  width: 97%;
  padding: 5px;
  height: auto;
  max-width: 600px;
  border-radius: 30px;
}
.tilesnotselected1 {
  display: flex;
  position: relative;
  width: 97%;
  padding: 5px;
  height: auto;
  max-height: 160px;
  max-width: 600px;
  border-radius: 30px;
}

.createphoto {
  display: flex;
  position: relative;
  top: 0%;
  width: 96%;
  border-radius: 30px;
  max-height: auto;
  padding: 5px;
  left: 50%;
  transform: translate(-50%);
}
.relative {
  display: flex;
  position: relative;
}
.typecircular {
  display: flex;
  position: relative;
  border-radius: 45px;
  height: 100px;
  width: 100px;
  align-self: right;
}
