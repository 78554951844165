.mfullcal_backdrop {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
}
.mfullcal_slide-drawer * {
  font-size: 3px;
  display: grid;
  grid-template-columns: 1fr;
  background-color: rgba(0, 0, 0, 0.699);
  position: fixed;
  border-radius: 30px;
  color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-out;
}
.mfullcal_slide-drawer.open * {
  transform: translate(-50%, 0);
}
.mclosecal {
  cursor: pointer;
  justify-self: center;
  display: flex;
  align-items: flex-end;
  font-size: 26px;
  padding-bottom: 20px;
  text-align: center;
}

.mback {
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 1;
  padding: 9px;
}
.mgototoday {
  display: flex;
  position: fixed;
  right: 4%;
  top: 0.5%;
  height: 20px;
  z-index: 1;
  margin-top: 14px;
  padding: 10px;
  font-size: 20px;
  color: #777;
  border-radius: 10%;
}
.mclockdialcal {
  display: flex;
  position: fixed;
  height: 100%;
  max-height: 40px;
}

.mclockbordercal {
  display: flex;
  position: fixed;
  height: 100%;
  max-height: 40px;
  z-index: -100;
}

.mCalendarHeader {
  justify-content: space-between;
}

.mCalendarMonth {
  color: rgb(206, 206, 206);
  text-align: center;
  padding: 0.5em 0.25em;
  word-spacing: 5px;
  user-select: none;
  font-size: 26px;
}
/*
.CalendarCell {
  text-align: center;
  align-self: center;
  font-size:26px;
  height: 9.5vw;
  user-select: none;
  grid-column:12vw;
  /*grid-column: ${props => (props.index % 7) + 1} / span 1;
}*/

.mCalendarWeekday {
  align-self: center;
  color: rgba(98, 123, 131, 0.878);
  border-top: 2px solid rgba(98, 123, 131, 0.878);
  border-bottom: 2px solid rgba(98, 123, 131, 0.878);
  font-size: 15px;
  text-align: center;
  /*border-right: ${props => (props.index % 7) + 1 === 7 ? `none` : `2px solid #06c`};*/
}

.mPrevPostDateNumber {
  display: flex;
  cursor: pointer;
  grid-row: 11%;
  height: 100%;
  max-height: 60px;
  color: rgba(98, 123, 131, 0.878);
  font-size: 22px;
  /*text-align:center;*/
  align-items: center;
  justify-content: center;
  border: 1px solid #222;
}
.mCalendarDateNumber {
  display: flex;
  cursor: pointer;
  grid-row: 11%;
  height: 100%;
  max-height: 60px;
  color: #c0c0c0c2;
  font-size: 22px;
  /*text-align:center;*/
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(112, 152, 167, 0.429);
  background-image: radial-gradient(
    rgba(255, 0, 0, 0),
    rgba(11, 57, 104, 0.374)
  );
}
/*.CalendarDateNumber:hover {
  color: white;
}*/
/*.PrevPostDateNumber:hover{
  color: white;
}*/
.mHighlightedCalendarDate {
  display: flex;
  color: white;
  position: relative;
  /*text-align:center;*/
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 40px;
  border: 1px solid #888;
  font-size: 22px;
  z-index: 9999;
} /*
.HighlightedCalendarDate::before {
  content: "";
  position: absolute;
}*/
.mTodayCalendarDate {
  display: flex;
  color: white;
  position: relative;
  /*background: transparent;
  text-align:center;*/
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 40px;
  border: 1px solid #222;
  font-size: 22px;

  background-image: radial-gradient(
    rgba(255, 0, 0, 0),
    rgba(18, 200, 250, 0.483)
  );
} /*
.TodayCalendarDate::after {
  content: "";
  position: absolute;
}*/

.mplacePlansOnCal {
  display: flex;
  position: fixed;
  height: 11%;
  max-height: 11%;
}
.mcontainplanscal {
  display: flex;
  position: fixed;
  height: 11%;
  max-height: 11%;
}

/*@media (max-width: 500px) {
  .mCalendarContainer {
    display: none;
    color: white;
    border-radius: 5px;
    padding-bottom: 17px;
    background-image: radial-gradient(
      rgba(0, 0, 0, 0.878),
      rgba(0, 0, 0, 0.878)
    );
  }
}*/

.mCalendarGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  font-size: 26px;
}

.mmonthlyskip {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  flex-direction: row;
  color: white;
  width: 245px;
}
.mmonthlyl {
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: white;
  padding: 15px;
  margin: 10px 0px;
  border: 1px solid white;
  border-radius: 30px;
  background-image: radial-gradient(
    rgba(255, 0, 0, 0),
    rgba(25, 81, 97, 0.748)
  );
}
.mmonthlyr {
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: white;
  padding: 15px;
  margin: 10px 0px;
  border: 1px solid white;
  border-radius: 30px;
  background-image: radial-gradient(
    rgba(255, 0, 0, 0),
    rgba(25, 81, 97, 0.748)
  );
}
.mgototoday {
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 26px;
  margin: 10px 0px;
  margin-left: 20px;
  text-align: center;
  color: white;
  padding: 15px;
  border: 1px solid white;
  border-radius: 30px;
  background-image: radial-gradient(
    rgba(255, 0, 0, 0),
    rgba(25, 81, 97, 0.748)
  );
}
.react-calendar {
  font-size: 8px;
  width: 100%;
  background: white;
  border: 1px solid #a0a096;
  line-height: 1.125em;
}
.react-calendar * {
  font-size: 13px;
}
@media (max-width: 300px) {
  .react-calendar * {
    font-size: 8px;
  }
}

.react-calendar--doubleView {
  width: max-content;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 100%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
@media (max-width: 300px) {
  .react-calendar__navigation {
    height: 22px;
    margin-bottom: 0.5em;
  }
  .react-calendar__navigation button {
    min-width: 22px;
    background: none;
  }
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(1em) calc(0.6666666666666666em);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
